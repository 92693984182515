<template>
  <div>
    <el-card class="box-card mb10">
      <el-form
        :inline="true"
        :model="ruleForm"
        label-suffix="："
        class="searchForm"
      >
        <el-row :gutter="24">
          <el-col :span="6">
            <el-form-item label="预警类型">
              <el-input
                v-model="ruleForm.type"
                placeholder="请输入预警类型"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="预警阈值">
              <el-input
                v-model="ruleForm.user"
                placeholder="请输入预警阈值"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="微信名">
              <el-input
                v-model="ruleForm.user"
                placeholder="请输入微信名"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="启用状态">
              <el-input
                v-model="ruleForm.user"
                placeholder="请输入启用状态"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="searchorrestbutton">
          <el-button
            type="primary"
            class="searchbutton"
            size="small"
            icon="el-icon-search"
            @click="getWarning()"
            >搜索
          </el-button>
          <el-button
            class="resetbutton"
            size="small"
            icon="el-icon-refresh-left"
            >重置</el-button
          >
        </div>
      </el-form>
    </el-card>
    <el-card class="box-card">
      <div class="mb10">
        <el-button type="primary" size="small" icon="el-icon-plus" @click="add"
          >添加</el-button
        >
      </div>
      <!-- 搜索与添加区域 -->
      <el-table
        header-align="center"
        :data="tableData"
        style="width: 100%"
        :border="true"
        max-height="600px"
        size="mini"
      >
        <el-table-column prop="name" label="姓名"></el-table-column>

        <el-table-column type="type" label="预警类型">
          <template slot-scope="scope">
            <span>{{ scope.row.type }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="value" label="预警阈值"></el-table-column>
        <el-table-column prop="name" label="微信帐号"> </el-table-column>
        <el-table-column prop="startDate" label="开始时间"> </el-table-column>
        <el-table-column prop="endDate" label="结束时间"> </el-table-column>
        <el-table-column prop="role_name" label="是否启用">
          <template slot-scope="scope">
            <span>{{ scope.row.status == 1 ? "是" : "否" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="" label="操作" width="180">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="编辑"
              :enterable="false"
              placement="top-end"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="editWaringPage(true, scope.row, '编辑')"
              >
              </el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="删除"
              placement="top-end"
              :enterable="false"
            >
              <el-popconfirm
                confirm-button-text="是的"
                cancel-button-text="先不删"
                icon="el-icon-info"
                icon-color="Gold"
                title="是否将该预警信息删除"
                @confirm="deleteuser(scope.row)"
              >
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  size="mini"
                  slot="reference"
                >
                </el-button>
              </el-popconfirm>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="复制"
              placement="top-end"
              :enterable="false"
            >
              <el-button
                type="warning"
                icon="el-icon-document-copy"
                size="mini"
                @click="editWaringPage(true, scope.row, '复制')"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <!-- <span class="demonstration">完整功能</span> -->
    </el-card>
    <add-row ref="addRow" @getWarning="getWarning"></add-row>
  </div>
</template>
<script>
// import Dialog from "./dialog.vue";
import addRow from "@/components/earlyWaringManage/addRow.vue";
import { getWarning, deleWarningPage } from "@/utils/masterdata.js";
export default {
  components: {
    addRow,
  },
  data() {
    return {
      id: "",
      centerDialogVisible: false,
      inputstates: false,
      addobtntate: "添加",
      ruleForm: {},

      // 获取用户列表的对象
      queryinfo: {
        query: "",
        // 当前的页数
      },
      userList: [],
      total: 0,
      tableData: [],
    };
  },

  mounted() {
    this.getWarning();
  },
  methods: {
    deleteuser(item) {
      console.log(item);
      deleWarningPage({ ...item }).then((res) => {
        this.$message.success("删除成功");
        this.getWarning();
      });
    },
    async getWarning() {
      const { body } = await getWarning({ ...this.ruleForm });
      this.tableData = body.map((e) => {
        return {
          account: e.account,
          createTime: e.createTime,
          endDate: e.endDateStr,
          id: e.id,
          name: e.name,
          startDate: e.startDateStr,
          status: e.status,
          type: e.type,
          unit: e.unit,
          updateTime: e.updateTime,
          value: e.value,
          userId: Number(e.userId),
        };
      });
    },
    add() {
      this.$refs.addRow.setVisible(true, null, "新增");
    },
    editWaringPage(visible, row, title) {
      console.log(row);
      this.$refs.addRow.setVisible(visible, row, title);
    },

    // 监听pagesize的改变
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.queryinfo.pagesize = val;
      this.getUserList();
    },
    // 监听页码值改变的事件
    handleCurrentChange(val) {
      this.queryinfo.pagenum = val;
      console.log(`当前页: ${val}`);
    },
  },
};
</script>
<style>
.cell > .el-button--mini {
  margin: 0 3px !important;
}
</style>
