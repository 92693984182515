<template>
  <el-dialog
    :title="form.title"
    :visible.sync="visible"
    width="30%"
    :before-close="handleClose"
  >
    <el-form ref="form" :model="form" label-width="100px" label-suffix="：">
      <el-form-item label="微信账号">
        <el-select
          @change="selectUser"
          v-model="form.userId"
          placeholder="请选择"
          style="width: 100%"
        >
          <el-option
            v-for="item in userList"
            :key="item.name"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="姓名">
        <el-input v-model="form.remark"></el-input>
      </el-form-item>
      <el-form-item label="单位">
        <el-input v-model="form.unit" readonly></el-input>
      </el-form-item>
      <el-form-item label="预警类型">
        <el-select v-model="form.type" placeholder="请选择" style="width: 100%">
          <el-option
            v-for="(item, index) in warningTypeList"
            :key="index"
            :label="item.label"
            :value="item.label"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="阈值">
        <el-input v-model="form.value"></el-input>
      </el-form-item>

      <el-form-item label="开始日期">
        <el-date-picker
          v-model="form.startDate"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
          type="date"
          style="width: 100%"
          placeholder="选择日期时间"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="结束日期">
        <el-date-picker
          v-model="form.endDate"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
          type="date"
          style="width: 100%"
          placeholder="选择日期时间"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="状态">
        <el-switch
          v-model="form.status"
          :inactive-value="0"
          :active-value="1"
          active-color="#13ce66"
          inactive-color="#ff4949"
        >
        </el-switch>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="addWarning">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
const form = {
  status: 1,
};
const warningTypeList = [
  { label: "SO2" },
  { label: "NO2" },
  { label: "PM10" },
  { label: "PM2.5" },
  { label: "O3" },
  { label: "O38" },
  { label: "NOx" },
  { label: "NO" },
];
import {
  addWarningPage,
  updateWarningPage,
  getWxUser,
} from "../../utils/masterdata";
export default {
  data() {
    return {
      warningTypeList,
      visible: false,
      form: { ...form, unit: "ug/m^3" },
      userList: [],
    };
  },
  mounted() {
    this.getWxUser();
  },
  methods: {
    selectUser(e) {
      this.form.name =
        this.userList[this.userList.findIndex((item) => item.id == e)].name;
    },
    async getWxUser() {
      const { body } = await getWxUser();
      this.userList = body;
    },
    setVisible(visible, row, title) {
      this.form.title = title;
      if (row && title == "编辑") {
        console.log(row.userId);
        this.form = { ...row };

        this.form.title = title;
      } else if (row && title == "复制") {
        console.log(row);

        this.form = { ...row };
        this.form.id = undefined;
        this.form.title = title;
      }
      this.visible = visible;
    },
    handleClose() {
      this.visible = false;
      this.form = { ...form };
    },
    async addWarning() {
      if (this.form.title == "新增" || this.form.title == "复制") {
        let res = await addWarningPage({ ...this.form });

        if (res.heads.code == 200) {
          this.$message.success(this.form.title + "成功");

          this.form = { ...form };
          this.visible = false;
          this.$emit("getWarning");
        }
      } else if (this.form.title == "编辑") {
        let res = await updateWarningPage({ ...this.form });
        console.log(res);
        if (res.heads.code == 200) {
          this.$message.success(this.form.title + "成功");

          this.form = { ...form };
          this.visible = false;
          this.$emit("getWarning");
        }
      }
    },
  },
};
</script>
<style></style>
